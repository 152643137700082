import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { StoreProvider } from "providers/store/store";

@Injectable({
  providedIn: "root",
})
export class FirebaseCloudMessagingService {
  Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    showCloseButton: true,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  constructor(private router: Router, private storage: StoreProvider) {}

  initPush() {
    if (
      Capacitor.getPlatform() == "android" ||
      Capacitor.getPlatform() == "ios"
    )
      this.registerPush();
  }

  private async registerPush() {
    await PushNotifications.addListener("registration", async (token) => {
      // console.info('Registration token: ', token.value);
      await this.storage.setItem("firebaseToken", token.value);
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification) => {
        // console.log('Push notification received: ', notification);
        // Swal.fire({
        //   title: '<p class="text-white">' + notification.title + "</p>",
        //   html: '<p class="text-white">' + notification.body + "</p>",
        //   // background: '#000',
        //   // icon: 'success',
        //   customClass: {
        //     confirmButton: "btn btn-success",
        //   },
        //   buttonsStyling: false,
        // });

        this.Toast.fire({
          title: notification.title,
          text: notification.body,
          iconHtml: '<i class="fas fa-sensor-alert"></i>',
          iconColor: "#b51d48",
          width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
        });
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
      }
    );

    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();

    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log("delivered notifications", notificationList);
  }
}
