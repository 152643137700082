import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "providers/auth.service";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { Capacitor } from "@capacitor/core";

// export const AppRoutes: Routes = [{
//         path: '',
//         redirectTo: 'dashboard',
//         pathMatch: 'full',
//       },{
//         path: '',
//         component: AdminLayoutComponent,
//         children: [{
//             path: '',
//             loadChildren: './dashboard/dashboard.module#DashboardModule'
//         },{
//             path: 'components',
//             loadChildren: './components/components.module#ComponentsModule'
//         },{
//             path: 'forms',
//             loadChildren: './forms/forms.module#Forms'
//         },{
//             path: 'tables',
//             loadChildren: './tables/tables.module#TablesModule'
//         },{
//             path: 'maps',
//             loadChildren: './maps/maps.module#MapsModule'
//         },{
//             path: 'charts',
//             loadChildren: './charts/charts.module#ChartsModule'
//         },{
//             path: 'calendar',
//             loadChildren: './calendar/calendar.module#CalendarModule'
//         },{
//             path: '',
//             loadChildren: './userpage/user.module#UserModule'
//         },{
//             path: '',
//             loadChildren: './timeline/timeline.module#TimelineModule'
//         },{
//             path: '',
//             loadChildren: './widgets/widgets.module#WidgetsModule'
//         }]
//         },{
//             path: '',
//             component: AuthLayoutComponent,
//             children: [{
//                 path: 'pages',
//                 loadChildren: './pages/pages.module#PagesModule'
//             }]
//         }
// ];

export const AppRoutes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' }, //for Android and iOS deployment
  // { path: '', redirectTo: 'aboutus', pathMatch: 'full' }, //for web deployment
  {
    path: "",
    redirectTo: Capacitor.getPlatform() === "web" ? "aboutus" : "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./layouts/admin/admin-layout.module").then(
            (m) => m.AdminLayoutModule
          ),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./layouts/auth/auth-layout.module").then(
            (m) => m.AuthLayoutModule
          ),
      },
    ],
  },
  { path: "**", redirectTo: "dashboard", canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
