import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VERSION } from 'app/app.component';
import { PrivacyPolicyComponent } from 'app/pages/app-area/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from 'app/pages/app-area/terms-and-conditions/terms-and-conditions.component';

@Component({
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {

    constructor(private location: Location,
        private modalService: NgbModal,
        public router: Router) { }

    today: Date = new Date();

    version: string = VERSION;

    async backClicked() {
        await this.location.back();
    }

    async seeTerms() {
        const modalRef = this.modalService.open(TermsAndConditionsComponent, { size: 'xl', centered: true, scrollable: true });
    }

    async seePrivacy() {
        const modalRef = this.modalService.open(PrivacyPolicyComponent, { size: 'xl', centered: true, scrollable: true });
    }

}
