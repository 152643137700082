import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { FixedPluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { FooterModule } from "./main/footer/footer.module";
import { NavbarModule } from "./main/navbar/navbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes, AppRoutingModule } from "./app.routing";
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from "ngx-cookieconsent";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { TagInputModule } from "ngx-chips";
import { ConfigService } from "providers/config/config.service";
import { EventEmitterService } from "providers/event-emitter/event-emitter.service";
import { AuthenticationService } from "providers/authentication.service";
import { UserService } from "providers/user.service";
import { StoreProvider } from "providers/store/store";
import { RedirectGuard } from "providers/redirect-guard";
import {
  NgbModule,
  NgbDatepicker,
  NgbToastModule,
} from "@ng-bootstrap/ng-bootstrap";
import { SidebarModule } from "./main/sidebar/sidebar.module";
import { CommonModule } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxDnDModule } from "@swimlane/ngx-dnd";
import { AvatarModule } from "ngx-avatar";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { DataSharingService } from "providers/data-sharing-service/data-sharing-service";
import { IvyCarouselModule } from "angular-responsive-carousel";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { FirebaseCloudMessagingService } from "providers/fcm/fcm.service";
import { NouisliderModule } from "ng2-nouislider";
import { NgxSpinnerModule } from "ngx-spinner";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgxBarcodeModule } from "ngx-barcode";
import { NgxPrintModule } from "ngx-print";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { CsvModule } from "@ctrl/ngx-csv";
import { Capacitor } from "@capacitor/core";
import { BluetoothProvider } from "providers/bluetooth/bluetooth";
import { NgxScannerQrcodeModule, LOAD_WASM } from "ngx-scanner-qrcode";
import { PdfViewerModule } from "ng2-pdf-viewer";

// LOAD_WASM().subscribe((res: any) => console.log("LOAD_WASM", res));

const cookieConfig: NgcCookieConsentConfig = {
  // Change to true when deploying in cloud. Cookie for specified domain i.e. haccpflow.ie. Change to new domain if needed.
  enabled: true,
  cookie: {
    // "domain": "localhost",
    // "domain": "haccpflow.com",
    domain: Capacitor.getPlatform() === "web" ? "haccpflow.com" : "localhost",
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#b51d48",
      text: "#000000",
      link: "#ffffff",
    },
    button: {
      background: "#000000",
      text: "#ffffff",
      border: "transparent",
    },
  },
  type: "info",
  content: {
    message:
      "This website stores cookies on your computer. These cookies are used to collect information about how you use our website and to remember you. We use this information to improve and personalize your browsing experience, and for analysis and measurement data about our visitors, both on this website and through other media. See our Privacy Policy for more information about the cookies we use.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: "/privacy",
    policy: "Cookie Policy",
  },
};

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    NgxScannerQrcodeModule,
    // RouterModule.forRoot(AppRoutes, {
    //     useHash: false,
    //     onSameUrlNavigation: 'reload'
    // }),
    NgbModule,
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedPluginModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgbToastModule,
    NgxDnDModule,
    // NgbDatepicker,
    AvatarModule,
    NgxChartsModule,
    AutocompleteLibModule,
    // ComponentsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    IvyCarouselModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgMultiSelectDropDownModule.forRoot(),
    TagInputModule,
    NouisliderModule,
    NgxBarcodeModule,
    NgxPrintModule,
    NgxQRCodeModule,
    CsvModule,
    PdfViewerModule,
    // NgxDnDModule.forRoot(),
  ],
  providers: [
    ConfigService,
    EventEmitterService,
    AuthenticationService,
    UserService,
    StoreProvider,
    BluetoothProvider,
    DataSharingService,
    RedirectGuard,
    PhotoViewer,
    FirebaseCloudMessagingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor() {
  //   console.log("cookieConfig", cookieConfig);
  // }
}
