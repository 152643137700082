import { Injectable } from "@angular/core";
import { BleClient, numberToUUID } from "@capacitor-community/bluetooth-le";
import { Capacitor } from "@capacitor/core";
import { ETIDeviceNames } from "app/configurations/configurations";
import { AuthenticationService } from "providers/authentication.service";
import { EventEmitterService } from "providers/event-emitter/event-emitter.service";
import { Subject } from "rxjs";
import Swal from "sweetalert2";

const GENERIC_ACCESS_SERVICE = numberToUUID(0x1800);

const BATTERY_SERVICE = numberToUUID(0x180f);
const BATTERY_CHARACTERISTIC = numberToUUID(0x2a19);

const PRIVATE_SERVICE = "45544942-4c55-4554-4845-524db87ad700";
const SENSOR_ONE_CHARACTERISTIC = "45544942-4c55-4554-4845-524db87ad701";

export const SCANNER_TIMER = 5000;

declare interface DEVICES {
  id: string;
  name: string;
}

@Injectable()
export class BluetoothProvider {
  public scannedDevices: DEVICES[] = [];
  public DEVICE_ID: string = null;
  public DEVICE_NAME: string = null;
  public batteryLevel: number = null;
  submitted: boolean = false;
  isAnAndroidDevice: boolean = Capacitor.getPlatform() === "android";
  // isAnAndroidDevice: boolean = true;

  public connectedProbe = false;
  public connectedProbeError = false;
  public autoConnectError = false;
  public autoConnectRequest = false;
  public temperatureReading: number = null;

  probeWasDisconnected: boolean = false;

  Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    showCloseButton: true,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  temperatureChange: Subject<number> = new Subject<number>();
  temperatureChangeError: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _authService: AuthenticationService
  ) // private emitterService: EventEmitterService
  {
    this.temperatureChange.subscribe((value) => {
      this.temperatureReading = value;

      // if (this.connectedProbe)
      //   this.emitterService.eventOnChange.emit("probeTemperatureUpdated");
    });
    this.temperatureChangeError.subscribe((value) => {
      this.connectedProbeError = value;
    });
  }

  async scan() {
    if (!this.isAnAndroidDevice || this.connectedProbe) return;

    this.scannedDevices = [];
    this.DEVICE_ID = null;
    this.DEVICE_NAME = null;
    this.temperatureReading = null;
    this.connectedProbe = false;
    this.connectedProbeError = false;
    this.probeWasDisconnected = false;
    this.autoConnectError = false;
    this.autoConnectRequest = false;

    try {
      await BleClient.initialize();
      await BleClient.requestLEScan(
        {
          // services: [PRIVATE_SERVICE],
          // optionalServices: [BATTERY_SERVICE, GENERIC_ACCESS_SERVICE],
        },
        (result) => {
          if (
            result.localName &&
            ETIDeviceNames.some((v) => result.localName.includes(v))
          ) {
            this.scannedDevices.push({
              id: result.device.deviceId,
              name: result.localName,
            });
          }

          // if (
          //   result.localName &&
          //   (result.localName.includes("ThermapenBlue") ||
          //     result.localName.includes("RayTemp Blue"))
          // ) {
          //   // console.log("received new scan result", result);
          //   this.scannedDevices.push({
          //     id: result.device.deviceId,
          //     name: result.localName,
          //   });
          // }
        }
      );

      setTimeout(async () => {
        await BleClient.stopLEScan();
        // console.log("stopped scanning");
      }, SCANNER_TIMER);

      // const device = await BleClient.requestDevice({
      //   services: [GENERIC_ACCESS_SERVICE],
      //   // optionalServices: [BATTERY_SERVICE, GENERIC_ACCESS_SERVICE],
      // });

      // console.log("device", device);

      // await BleClient.connect(DEVICE_ID, (deviceId) =>
      //   this.onDisconnect(deviceId)
      // );
      // console.log("connected to device", DEVICE_ID);

      // const battery = await BleClient.read(
      //   DEVICE_ID,
      //   BATTERY_SERVICE,
      //   BATTERY_CHARACTERISTIC
      // );
      // console.log("battery level", battery.getUint8(0));
      // await this.setVariables();
    } catch (error) {
      console.error(error);
    }
  }

  async requestDevice(deviceID, deviceName) {
    if (!this.isAnAndroidDevice || this.connectedProbe) return;

    this.autoConnectRequest = true;

    this.DEVICE_ID = deviceID;
    this.DEVICE_NAME = deviceName;
    this.submitted = true;
    this.scannedDevices = [];
    this.temperatureReading = null;
    this.connectedProbe = false;
    this.connectedProbeError = false;
    this.probeWasDisconnected = false;

    if (!this.DEVICE_ID) {
      this.submitted = false;
      // await this.setVariables();
      return;
    }

    await BleClient.initialize();
    await BleClient.connect(this.DEVICE_ID, (deviceId) => {
      this.onDisconnect(deviceId);
    });

    this.connectedProbe = true;
    this.autoConnectRequest = false;

    await this._authService.setBluetoothName(this.DEVICE_NAME);
    await this._authService.setBluetooth(this.DEVICE_ID);
    // console.log("connected to device", this.DEVICE_ID);

    const batery = await BleClient.read(
      this.DEVICE_ID,
      BATTERY_SERVICE,
      BATTERY_CHARACTERISTIC
    );

    this.batteryLevel = batery.getUint8(0);
    if (this.batteryLevel < 20) {
      this.Toast.fire({
        title: "Low battery",
        text: this.DEVICE_NAME + " battery level is low, please replace it.",
        iconHtml: '<i class="fas fa-sensor-alert"></i>',
        iconColor: "#b51d48",
        width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
      });
    }

    // const reading = await BleClient.read(
    //   this.DEVICE_ID,
    //   PRIVATE_SERVICE,
    //   SENSOR_ONE_CHARACTERISTIC
    // );
    // console.log("reading", reading);
    // this.temperatureReading = reading.getUint32(0, true) / 100;
    // console.log("temperatureReading", this.temperatureReading);

    await BleClient.startNotifications(
      this.DEVICE_ID,
      PRIVATE_SERVICE,
      SENSOR_ONE_CHARACTERISTIC,
      async (value) => {
        let temperatureReading: number = null;
        let connectedProbeError: boolean = false;
        // 4 * uint8: [ 0xff, 0xff, 0xff, 0xff ] signifying
        // Sensor Fault
        if (
          value.getUint8(0) === 0xff &&
          value.getUint8(1) === 0xff &&
          value.getUint8(2) === 0xff &&
          value.getUint8(3) === 0xff
        ) {
          temperatureReading = null;
          connectedProbeError = true;
          this.Toast.fire({
            title: "Faulty Probe",
            text:
              this.DEVICE_NAME +
              " probe's sensor fault, please try again, if the problem persists, please contact support.",
            iconHtml: '<i class="fas fa-sensor-alert"></i>',
            iconColor: "#b51d48",
            width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
          });
          // this.emitterService.eventOnChange.emit("probeTemperatureUpdated");
          return;
        } else {
          // IEEE-754 32-bit floating point (little-endian)
          // (in ºC & trim-compensated)
          temperatureReading = value.getFloat32(0, true);
          temperatureReading = Math.round(temperatureReading * 100) / 100;
          connectedProbeError = false;
          //   console.log("currentTemp", temperatureReading);
        }
        this.temperatureChange.next(temperatureReading);
        this.temperatureChangeError.next(connectedProbeError);
      }
    );
    this.submitted = false;
  }

  async connectDevice(deviceID, deviceName) {
    this.DEVICE_ID = deviceID;
    this.DEVICE_NAME = deviceName;
    this.submitted = true;

    if (!this.DEVICE_ID) {
      this.submitted = false;
      // await this.setVariables();
      return;
    }

    await BleClient.connect(this.DEVICE_ID, (deviceId) => {
      this.onDisconnect(deviceId);
    });

    this.connectedProbe = true;
    await this._authService.setBluetoothName(this.DEVICE_NAME);
    await this._authService.setBluetooth(this.DEVICE_ID);
    // console.log("connected to device", this.DEVICE_ID);

    const batery = await BleClient.read(
      this.DEVICE_ID,
      BATTERY_SERVICE,
      BATTERY_CHARACTERISTIC
    );

    this.batteryLevel = batery.getUint8(0);
    if (this.batteryLevel < 20) {
      this.Toast.fire({
        title: "Low battery",
        text: this.DEVICE_NAME + " battery level is low, please replace it.",
        iconHtml: '<i class="fas fa-sensor-alert"></i>',
        iconColor: "#b51d48",
        width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
      });
    }

    // const reading = await BleClient.read(
    //   this.DEVICE_ID,
    //   PRIVATE_SERVICE,
    //   SENSOR_ONE_CHARACTERISTIC
    // );
    // console.log("reading", reading);
    // this.temperatureReading = reading.getUint32(0, true) / 100;
    // console.log("temperatureReading", this.temperatureReading);

    await BleClient.startNotifications(
      this.DEVICE_ID,
      PRIVATE_SERVICE,
      SENSOR_ONE_CHARACTERISTIC,
      async (value) => {
        let temperatureReading: number = null;
        let connectedProbeError: boolean = false;
        // 4 * uint8: [ 0xff, 0xff, 0xff, 0xff ] signifying
        // Sensor Fault
        if (
          value.getUint8(0) === 0xff &&
          value.getUint8(1) === 0xff &&
          value.getUint8(2) === 0xff &&
          value.getUint8(3) === 0xff
        ) {
          temperatureReading = null;
          this.connectedProbeError = true;
          this.Toast.fire({
            title: "Faulty Probe",
            text:
              this.DEVICE_NAME +
              " probe's sensor fault, please try again, if the problem persists, please contact support.",
            iconHtml: '<i class="fas fa-sensor-alert"></i>',
            iconColor: "#b51d48",
            width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
          });
          // this.emitterService.eventOnChange.emit("probeTemperatureUpdated");
          return;
        } else {
          // IEEE-754 32-bit floating point (little-endian)
          // (in ºC & trim-compensated)
          temperatureReading = value.getFloat32(0, true);
          temperatureReading = Math.round(temperatureReading * 100) / 100;
          this.connectedProbeError = false;
          connectedProbeError = false;
          //   console.log("currentTemp", temperatureReading);
        }
        this.temperatureChange.next(temperatureReading);
        this.temperatureChangeError.next(connectedProbeError);
      }
    );

    this.submitted = false;
  }

  async onDisconnect(deviceId: string) {
    if (this.probeWasDisconnected) return;

    if (!this.autoConnectRequest) {
      this.Toast.fire({
        title: "Probe Lost".toUpperCase(),
        text: this.DEVICE_NAME + " probe lost, please try again.",
        iconHtml: '<i class="fas fa-sensor-alert"></i>',
        iconColor: "#b51d48",
        width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
      });
      // this.emitterService.eventOnChange.emit("probeTemperatureUpdated");
    }
    // console.log(`device ${deviceId} disconnected`);
    else {
      this.autoConnectError = true;
      await this._authService.setBluetoothName("");
      await this._authService.setBluetooth("");
      this.Toast.fire({
        title: "Last Probe Not Found".toUpperCase(),
        text: "Scan for nearby probes and try again.",
        // text: "Scanning for nearby probes ...",
        iconHtml: '<i class="fas fa-sensor-alert"></i>',
        iconColor: "#b51d48",
        width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
      });
      // this.emitterService.eventOnChange.emit("probeTemperatureUpdated");
    }
    await this.disconnectService();
  }

  async DisconnectDevice() {
    if (this.autoConnectRequest) return;
    this.probeWasDisconnected = true;
    this.Toast.fire({
      title: "Probe Disconnected".toUpperCase(),
      text: this.DEVICE_NAME + " probe has been disconnected.",
      iconHtml: '<i class="fas fa-sensor-alert"></i>',
      iconColor: "#b51d48",
      width: window.innerWidth < 800 ? window.innerWidth - 50 : 800,
    });
    // this.emitterService.eventOnChange.emit("probeTemperatureUpdated");
    await this._authService.setBluetoothName("");
    await this._authService.setBluetooth("");
    await BleClient.disconnect(this.DEVICE_ID);
    await this.disconnectService();
    // console.log(`device ${this.DEVICE_ID} has disconnected`);
  }

  async disconnectService() {
    if (!this.DEVICE_ID) return;

    this.DEVICE_NAME = null;
    this.temperatureReading = null;
    this.connectedProbe = false;
    this.connectedProbeError = false;
    this.scannedDevices = [];
    this.probeWasDisconnected = false;

    await BleClient.stopNotifications(
      this.DEVICE_ID,
      PRIVATE_SERVICE,
      SENSOR_ONE_CHARACTERISTIC
    );

    this.DEVICE_ID = null;

    // await this._authService.setBluetoothName("");
    // await this._authService.setBluetooth("");
  }
}
