import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "providers/authentication.service";
import { ConfigService } from "providers/config/config.service";
import { first } from "rxjs/operators";
import Swal from "sweetalert2";

export interface USER {
  id: string;
  fullName: string;
  name: string;
  surname: string;
  publicid: string;
  avatar: string;
  token: string;
}

@Component({
  selector: "app-change-user",
  templateUrl: "./change-user.component.html",
  styleUrls: ["./change-user.component.css"],
})
export class ChangeUserComponent implements OnInit {
  @Input() user: string;
  @Input() token: string;
  @Input() id_company: string;

  loading: boolean = true;
  showError: boolean = false;
  error: string = "";

  allUsersRecords: any;
  public usersRecords: USER[] = [];
  public usersRecordsTemp: USER[] = [];

  Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  constructor(
    private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    public activeModal: NgbActiveModal,
    public router: Router
  ) {}

  async ngOnInit() {
    await this.getAllUsers();
  }

  Search(event) {
    const val = event.toLowerCase();
    // const val = event.target.value.toLowerCase();
    this.usersRecordsTemp.forEach((item) =>
      Object.keys(item).forEach(
        (k) =>
          (item[k] = item[k] == null || item[k] == undefined ? "" : item[k])
      )
    );
    const temp = this.usersRecordsTemp.filter(function (d) {
      return d.name.toString().toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.usersRecords = temp;
  }

  async getAllUsers() {
    const userVm = {
      id: this.user,
      token: this.token,
    };

    await (
      await this._authService.getCompanyAllUsersChangeUser(userVm)
    ).subscribe(
      (newData) => {
        this.allUsersRecords = [];
        this.usersRecords = [];
        this.allUsersRecords = newData;
        for (let index = 0; index < this.allUsersRecords.length; index++) {
          const user = this.allUsersRecords[index];
          if (user._id != this.user)
            this.usersRecords.push({
              id: user._id,
              fullName: user["name"] + " " + user["surname"],
              name: user["name"],
              surname: user["surname"],
              publicid: user["publicid"],
              token: user["token"],
              avatar: this._configService.url + user["avatar"],
            });
        }

        this.usersRecords.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        this.usersRecordsTemp = this.usersRecords;
        this.loading = false;
      },
      (err) => {
        console.log("error");
        this.loading = false;
      }
    );
  }

  async changeUser(user: USER) {
    let tokenLogin = {
      id: user.id,
      token: user.token,
      firebaseToken: "",
    };

    await (await this._authService.tokenChangeUser(tokenLogin))
      .pipe(first())
      .subscribe(
        async (data) => {
          // await this._authService.logout();
          // this.router.navigate(["/dashboard"]);
          this.Toast.fire({
            icon: "success",
            title: " The user has been changed! ",
          });
          this.activeModal.close();
        },
        (err) => {
          this.showError = true;
          this.error = "An error has occurred.";
          if (err.error.message) this.error = err.error.message;
          this.Toast.fire({
            icon: "error",
            title:
              " The user has not been changed! " +
              this.error +
              " Please Try Again.",
          });
          this.loading = false;
        }
      );
  }
}
