import { first } from "rxjs/operators";
import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Directive,
  ViewChildren,
  QueryList,
} from "@angular/core";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { Subscription } from "rxjs/Subscription";
import { ROUTES } from "../sidebar/sidebar.component";
import { AuthenticationService } from "providers/authentication.service";
import { ConfigService } from "providers/config/config.service";
import * as moment from "moment";
import { EventEmitterService } from "providers/event-emitter/event-emitter.service";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangeUserComponent } from "app/pages/app-area/change-user/change-user.component";
// import * as schedule from "node-schedule";
import {
  BluetoothProvider,
  SCANNER_TIMER,
} from "providers/bluetooth/bluetooth";
import { Capacitor } from "@capacitor/core";
import { StoreProvider } from "providers/store/store";
import { timer } from "rxjs";

declare interface DEVICES {
  id: string;
  name: string;
}

var misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

export interface NOTIFICATION {
  id: string;
  title: string;
  body: string;
  path: string;
  icon: string;
  type: string;
  read: boolean;
  sender: string;
  createdAt: Date;
  timeFromNow: string;
}

@Component({
  moduleId: module.id,
  selector: "navbar-cmp",
  styleUrls: ["./navbar.component.css"],
  templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public open: boolean = false;

  @ViewChild("navbar-cmp", { static: false }) button;

  private subscriptionUser: Subscription;
  private _serviceNotificationSubscription: Subscription;
  // private _probeTemperatureSubscription: Subscription;

  searchtext: string = "";
  // @ViewChildren("search") search: QueryList<ElementRef>;
  @ViewChild("search") search: ElementRef;

  user = {
    id: "",
    token: "",
    name: "",
    fullName: "",
    initials: "",
    email: "",
    avatar: "",
    publicid: "",
    company: "",
    id_company: "",
    group: "",
    id_group: "",
    notification: 0,
    emailVerification: null,
  };

  allNotificationsRecords: any;
  notificationsRecords: NOTIFICATION[] = [];

  admin: boolean = false;

  skip = 0;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  loading = true;

  scannedDevices: DEVICES[] = [];
  DEVICE_ID: string = null;
  DEVICE_NAME: string = null;
  batteryLevel: number = null;
  submitted: boolean = false;
  isAnAndroidDevice: boolean = Capacitor.getPlatform() === "android";
  isTherProbeConnecteSubscription: boolean = false;
  // isAnAndroidDevice: boolean = true;

  connectedProbe = false;
  connectedProbeError = false;

  autoConnectError = false;

  scanning = false;
  connecting = false;

  temperatureReading: number = null;

  isServerDown: boolean = false;

  constructor(
    location: Location,
    private storage: StoreProvider,
    private renderer: Renderer2,
    public bluetooth: BluetoothProvider,
    private element: ElementRef,
    private router: Router,
    private _authService: AuthenticationService,
    private modalService: NgbModal,
    private emitterService: EventEmitterService,
    private readonly _configService: ConfigService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this._serviceNotificationSubscription =
      this.emitterService.eventOnChange.subscribe({
        next: (event: string) => {
          if (event == "updateNotification") {
            this.loading = true;
            this.notificationsRecords = [];
            this.skip = 0;
            this.getAllNotifications();
          }
        },
      });

    // this._probeTemperatureSubscription =
    //   this.emitterService.eventOnChange.subscribe({
    //     next: async (event: string) => {
    //       if (event == "probeTemperatureUpdated") {
    //         this.connectedProbe = this.bluetooth.connectedProbe;

    //         if (this.connectedProbe) {
    //           this.connectedProbeError = this.bluetooth.connectedProbeError;
    //           this.temperatureReading = this.bluetooth.temperatureReading;
    //           this.DEVICE_ID = this.bluetooth.DEVICE_ID;
    //           this.DEVICE_NAME = this.bluetooth.DEVICE_NAME;
    //           this.scannedDevices = this.bluetooth.scannedDevices;
    //           this.autoConnectError = this.bluetooth.autoConnectError;

    //           if (this.connectedProbe) {
    //             this.connecting = false;
    //             this.scanning = false;
    //           }
    //         }

    //         if (this.autoConnectError && !this.scanning) {
    //           this.connecting = false;
    //           this.autoConnectError = false;
    //           await this.scan();
    //         }
    //       }
    //     },
    //   });
  }

  async ngOnInit() {
    // var rule = new schedule.RecurrenceRule();
    // // rule.minute = new schedule.Range(0, 0, 20);
    // rule.second = 0;

    // schedule.scheduleJob(rule, () => {

    // });

    const notificationCheck = timer(0, 1000 * 60 * 2);
    notificationCheck.subscribe(async () => {
      if (this._authService.isAuthorized())
        this.emitterService.eventOnChange.emit("updateNotification");
    });

    this.listTitles = ROUTES.filter((listTitle) => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const $layer = document.getElementsByClassName("close-layer")[0];
        if ($layer) {
          $layer.remove();
        }
      });

    this.subscriptionUser = this._authService.authUserChange.subscribe(
      async (value) => {
        let user: any[] = value ? JSON.parse(value) : {};
        this.user.id = user["_id"];
        this.user.token = user["token"];
        this.user.name = user["name"];
        this.user.fullName = user["name"] + " " + user["surname"];
        this.user.initials =
          user["name"].charAt(0).toUpperCase() +
          user["surname"].charAt(0).toUpperCase();
        this.user.email = user["email"];
        this.user.emailVerification = user["emailVerification"];
        this.user.publicid = user["publicid"];
        this.user.avatar = this._configService.url + user["avatar"];
        this.user.company = user["company"]
          ? user["company"].name
          : "HACCP Flow";
        this.user.id_company = user["company"] ? user["company"]._id : "";
        this.user.group = user["group"] ? user["group"].name : "";
        this.user.id_group = user["group"] ? user["group"]._id : "";

        if (user["role"] == "Admin") this.admin = true;
        else this.admin = false;

        this.isTherProbeConnecteSubscription =
          user["company"] && user["company"].subscriptions.probeConnect
            ? true
            : false;

        const isThereASystem = await this.storage.getItem("system");
        let system: any[] = isThereASystem ? JSON.parse(isThereASystem) : {};
        this.isServerDown = system["isServerDown"];
      }
    );

    // this._authService.authUserNotifications.subscribe((value) => {
    //   // this.notificationsRecords = [];
    //   let notifications: any[] = value ? JSON.parse(value) : null;
    //   if (notifications) {
    //     this.allNotificationsRecords = notifications["notifications"];
    //     if (notifications["totalNotification"] != 0)
    //       this.user.notification = notifications["totalNotifications"];
    //     else this.user.notification = null;

    //     this.allNotificationsRecords.forEach((notification) => {
    //       let sender: string = null;
    //       if (notification.type == "friendRequest")
    //         sender =
    //           notification.sender.name + " " + notification.sender.surname;
    //       this.notificationsRecords.push({
    //         id: notification._id,
    //         title: notification.title,
    //         body:
    //           notification.body.length > 140
    //             ? notification.body.substring(0, 140) + "..."
    //             : notification.body,
    //         read: notification.read,
    //         icon: notification.icon,
    //         sender: sender,
    //         path: notification.path,
    //         type: notification.type,
    //         createdAt: notification.createdAt,
    //         timeFromNow: this.transform(notification.createdAt),
    //       });
    //     });

    //     this.notificationsRecords.sort(function (a, b) {
    //       if (a.createdAt > b.createdAt) {
    //         return -1;
    //       }
    //       if (a.createdAt < b.createdAt) {
    //         return 1;
    //       }
    //       return 0;
    //     });
    //   }
    // });

    await this.getAllNotifications();

    // if (this.isAnAndroidDevice) {
    //   let button = document.getElementById("scan-probe");
    //   button.addEventListener("click", async () => {
    //     this.connectedProbeError = false;
    //     if (!this.connectedProbe)
    //       try {
    //         await this.scan();
    //       } catch (error) {
    //         // this.connectedProbe = false;
    //         this.connectedProbeError = true;
    //         console.log(error);
    //       }
    //     // await this.scale.close()
    //   });
    // }
    if (this.isTherProbeConnecteSubscription) {
      this.connectedProbe = this.bluetooth.connectedProbe;
      this.connectedProbeError = this.bluetooth.connectedProbeError;
      this.temperatureReading = this.bluetooth.temperatureReading;
      this.DEVICE_ID = this.bluetooth.DEVICE_ID;
      this.batteryLevel = this.bluetooth.batteryLevel;
      this.DEVICE_NAME = this.bluetooth.DEVICE_NAME;
      this.scannedDevices = this.bluetooth.scannedDevices;
      this.autoConnectError = false;

      const probeCheck = timer(0, 1000);
      probeCheck.subscribe(async () => {
        if (this.connectedProbe) {
          this.connectedProbe = this.bluetooth.connectedProbe;
          this.connectedProbeError = this.bluetooth.connectedProbeError;
          this.temperatureReading = this.bluetooth.temperatureReading;
          this.DEVICE_ID = this.bluetooth.DEVICE_ID;
          this.batteryLevel = this.bluetooth.batteryLevel;
          this.DEVICE_NAME = this.bluetooth.DEVICE_NAME;
          this.scannedDevices = this.bluetooth.scannedDevices;
          this.autoConnectError = this.bluetooth.autoConnectError;

          if (this.connectedProbe) {
            this.connecting = false;
            this.scanning = false;
          }
        }

        if (this.autoConnectError && !this.scanning) {
          this.connecting = false;
          this.autoConnectError = false;
          await this.scan();
        }
      });

      // const temperatureRule = "*/1 * * * * *";
      // schedule.scheduleJob(temperatureRule, async () => {
      //   if (this.connectedProbe) {
      //     this.connectedProbe = this.bluetooth.connectedProbe;
      //     this.connectedProbeError = this.bluetooth.connectedProbeError;
      //     this.temperatureReading = this.bluetooth.temperatureReading;
      //     this.DEVICE_ID = this.bluetooth.DEVICE_ID;
      //     this.DEVICE_NAME = this.bluetooth.DEVICE_NAME;
      //     this.scannedDevices = this.bluetooth.scannedDevices;
      //     this.autoConnectError = this.bluetooth.autoConnectError;

      //     if (this.connectedProbe) {
      //       this.connecting = false;
      //       this.scanning = false;
      //     }
      //   }

      //   if (this.autoConnectError && !this.scanning) {
      //     this.connecting = false;
      //     this.autoConnectError = false;
      //     await this.scan();
      //   }
      //   // else schedule.cancelJob();
      // });
    }
  }

  ngOnDestroy() {
    this.subscriptionUser.unsubscribe();
    this._serviceNotificationSubscription.unsubscribe();
    // this._probeTemperatureSubscription.unsubscribe();

    // this.bluetooth.temperatureChange.unsubscribe();
    // schedule.cancelJob();
  }

  transform(value: string) {
    let now = moment(value).fromNow();
    return now;
  }

  ngAfterViewInit() {
    // this.minimizeSidebar();
  }

  async getAllNotifications() {
    let notificationVm = {
      id: this.user.id,
      token: this.user.token,
      skip: this.skip,
    };

    // await (
    //   await this._authService.getNavbarNotifications(notificationVm)
    // ).subscribe();

    await (
      await this._authService.getAllNotifications(notificationVm)
    ).subscribe(
      (newData) => {
        this.allNotificationsRecords = newData.notifications;
        if (newData.totalNotification != 0)
          this.user.notification = newData.totalNotifications;
        else this.user.notification = null;

        for (
          let index = 0;
          index < this.allNotificationsRecords.length;
          index++
        ) {
          const notification = this.allNotificationsRecords[index];
          let sender: string = null;
          if (notification.type == "friendRequest")
            sender =
              notification.sender.name + " " + notification.sender.surname;
          this.notificationsRecords.push({
            id: notification._id,
            title: notification.title,
            body:
              notification.body.length > 140
                ? notification.body.substring(0, 140) + "..."
                : notification.body,
            read: notification.read,
            path: notification.path,
            type: notification.type,
            icon: notification.icon,
            sender: sender,
            createdAt: notification.createdAt,
            timeFromNow: this.transform(notification.createdAt),
          });
        }
        this.notificationsRecords.sort(function (a, b) {
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          return 0;
        });

        this.loading = false;
      },
      (err) => {
        this.loading = true;
        console.log("error");
      }
    );
  }

  async markAllNotificationAsRead() {
    let notificationVm = {
      id: this.user.id,
      token: this.user.token,
    };

    await (
      await this._authService.markAllNotificationsAsRead(notificationVm)
    ).subscribe(
      (newData) => {
        this.emitterService.eventOnChange.emit("updateNotification");
      },
      (err) => {
        console.log("error");
      }
    );
  }

  async markNotificationAsRead(
    path: string,
    notification: string,
    index: number
  ) {
    let notificationVm = {
      id: this.user.id,
      token: this.user.token,
      id_notification: notification,
      read: true,
    };

    if (!this.notificationsRecords[index].read)
      await (
        await this._authService.markNotificationAsRead(notificationVm)
      ).subscribe(
        (newData) => {
          this.emitterService.eventOnChange.emit("updateNotification");
        },
        (err) => {
          console.log("error");
        }
      );

    if (path) {
      console.log("path", path);
    }
  }

  backButton() {
    this.location.back();
  }

  onScrollDown() {
    this.skip = this.notificationsRecords.length;
    this.getAllNotifications();
    // console.log("scrolled down!!");
  }

  onScrollUp() {
    // console.log("scrolled up!!");
  }

  async logout() {
    await this._authService.logout();
    this.router.navigate(["/login"]);
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  // isMobileMenu() {
  //     if (window.outerWidth < 991) {
  //         return false;
  //     }
  //     return true;
  // }

  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName("html")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }
    html.classList.add("nav-open");
    this.sidebarVisible = true;
  }

  sidebarClose() {
    var html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 500);
    }
  }

  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible == false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      var parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.title;
      } else if (parent.children) {
        // var children_from_url = titlee.split("/")[2];
        var children_from_url = titlee.split("/")[1];
        for (var current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    return "Dashboard";
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  changeUser() {
    const modalRef = this.modalService.open(ChangeUserComponent, {
      size: "xl",
      centered: true,
      backdrop: "static",
    });
    modalRef.componentInstance.user = this.user.id;
    modalRef.componentInstance.token = this.user.token;
    modalRef.componentInstance.id_company = this.user.id_company;
  }

  async scan() {
    if (
      !this.isAnAndroidDevice ||
      this.connectedProbe ||
      !this.isTherProbeConnecteSubscription
    )
      return;

    this.scannedDevices = [];

    //check if there is previous connection info
    const previousDeviceId = await this._authService.getBluetooth();
    const previousDeviceName = await this._authService.getBluetoothName();

    if (previousDeviceId && previousDeviceName) {
      this.DEVICE_ID = previousDeviceId;
      this.DEVICE_NAME = previousDeviceName;
      this.requestDevice();
    } else {
      console.log("here");
      this.scanning = true;
      await this.bluetooth.scan();
      this.scannedDevices = this.bluetooth.scannedDevices;

      setTimeout(async () => {
        this.scanning = false;
      }, SCANNER_TIMER);
    }
  }

  async onDeviceChange(deviceId: string) {
    const device = this.scannedDevices.find((x) => x.id === deviceId);
    this.DEVICE_ID = device.id;
    this.DEVICE_NAME = device.name;
  }

  async connectDevice() {
    this.submitted = true;
    this.connecting = true;
    await this.bluetooth.connectDevice(this.DEVICE_ID, this.DEVICE_NAME);
    this.connectedProbe = this.bluetooth.connectedProbe;
    this.connectedProbeError = this.bluetooth.connectedProbeError;
    this.submitted = false;

    setTimeout(async () => {
      this.connecting = false;
    }, SCANNER_TIMER);

    // this.bluetooth.temperatureChange.subscribe((value) => {
    //   console.log("value", value);
    //   this.temperatureReading = Number(value);
    // });

    // }
  }

  async requestDevice() {
    this.submitted = true;
    this.connecting = true;

    setTimeout(async () => {
      this.connecting = false;
      this.autoConnectError = this.bluetooth.autoConnectError;
      // if (this.autoConnectError) await this.scan();
    }, 2 * SCANNER_TIMER);

    await this.bluetooth.requestDevice(this.DEVICE_ID, this.DEVICE_NAME);
    this.connectedProbe = this.bluetooth.connectedProbe;
    this.connectedProbeError = this.bluetooth.connectedProbeError;
    this.submitted = false;

    // this.bluetooth.temperatureChange.subscribe((value) => {
    //   console.log("value", value);
    //   this.temperatureReading = Number(value);
    // });

    // }
  }

  async DisconnectDevice() {
    await this.bluetooth.DisconnectDevice();
    this.DEVICE_ID = null;
    this.DEVICE_NAME = null;
    this.batteryLevel = null;
    this.temperatureReading = null;
    this.connectedProbe = false;
    this.connectedProbeError = false;
    this.scannedDevices = [];
  }
}
