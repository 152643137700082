export declare interface DROPDOWNTYPETAGDISPLAY {
  display: string;
  value: string;
}
declare interface USERROLETYPE {
  value: string;
  role: string;
}
export const userRoleType: USERROLETYPE[] = [
  { value: "Admin", role: "System Administrator" },
  { value: "User", role: "User" },
  { value: "CompanyAdmin", role: "Company Administrator" },
  { value: "GroupAdmin", role: "Group Administrator" },
];

export const userCompanyRoleType: USERROLETYPE[] = [
  { value: "User", role: "User" },
  { value: "CompanyAdmin", role: "Company Administrator" },
];

export const userGroupRoleType: USERROLETYPE[] = [
  { value: "User", role: "User" },
  { value: "CompanyAdmin", role: "Company Administrator" },
  { value: "GroupAdmin", role: "Group Administrator" },
];

export const haccpStatus: DROPDOWNTYPETAGDISPLAY[] = [
  { display: "Aftercook", value: "AFTERCOOK" },
  { display: "Hot Hold", value: "HOT_HOLD" },
  { display: "Cold Hold", value: "COLD_HOLD" },
  { display: "Blast Chill", value: "BLAST_CHILL" },
  { display: "Reheat", value: "REHEAT" },
  { display: "Thaw", value: "THAW" },
  { display: "Frozen", value: "FROZEN" },
];

export enum haccpStatusValues {
  AFTERCOOK = "AFTERCOOK",
  HOT_HOLD = "HOT_HOLD",
  COLD_HOLD = "COLD_HOLD",
  BLAST_CHILL = "BLAST_CHILL",
  REHEAT = "REHEAT",
  THAW = "THAW",
  FROZEN = "FROZEN",
}

export enum haccpStatusTextValues {
  AFTERCOOK = "Aftercook",
  HOT_HOLD = "Hot Hold",
  COLD_HOLD = "Cold Hold",
  BLAST_CHILL = "Blast Chill",
  REHEAT = "Reheat",
  THAW = "Thaw",
  FROZEN = "Frozen",
}

export enum haccpStatusTemperatureValues {
  AFTERCOOK_MIN = 74,
  HOT_HOLD_MIN = 64,
  COLD_HOLD_MIN = 0,
  COLD_HOLD_MAX = 5,
  BLAST_CHILL_MIN = 74,
  REHEAT_MIN = 72,
  // THAW = 100,
}

export const defaultLocations: string[] = [
  "COLD STORAGE",
  "FREEZER",
  "BLAST CHILLER",
];

export enum CleaningFrequencys {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}

export const cleaningFrequencys: DROPDOWNTYPETAGDISPLAY[] = [
  { display: "Daily", value: "DAILY" },
  { display: "Weekly", value: "WEEKLY" },
];

export enum TemperatureCheckTypes {
  AM = "AM",
  PM = "PM",
}

//ETI DEVICE'S NAMES
export const ETIDeviceNames: string[] = [
  "ThermapenBlue",
  "RayTemp Blue",
  "TempTest Blue",
  "Thermapen IR",
];

// Allergens
export const allergens: DROPDOWNTYPETAGDISPLAY[] = [
  { display: "Celery and products thereof", value: "CELERY" },
  {
    display: "Cereals containing gluten and products thereof",
    value: "CEREALS_CONTAINING_GLUTEN",
  },
  { display: "Crustaceans and products thereof", value: "CRUSTACEANS" },
  { display: "Eggs and products thereof", value: "EGGS" },
  { display: "Fish and products thereof", value: "FISH" },
  { display: "Lupin and products thereof", value: "LUPIN" },
  { display: "Milk and products thereof", value: "MILK" },
  { display: "Molluscs and products thereof", value: "MOLLUSCS" },
  { display: "Mustard and products thereof", value: "MUSTARD" },
  { display: "Nuts and products thereof", value: "NUTS" },
  { display: "Peanuts and products thereof", value: "PEANUTS" },
  { display: "Sesame seeds and products thereof", value: "SESAME_SEEDS" },
  { display: "Soya and products thereof", value: "SOYA" },
  {
    display: "Sulphur dioxide and sulphites and products thereof",
    value: "SULPHUR_DIOXIDE",
  },
];

export enum TemperatureAlertRange {
  LOW_TEMPERATURE = 'lowTemperature',
  COLD_RANGE = 'coldRange',
}

export enum HumidityAlertRange {
  HIMALAYAN = 'himalayan',
  ABATTOIR = 'abattoir',
}