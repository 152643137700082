import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthenticationService } from "providers/authentication.service";
import { ConfigService } from "providers/config/config.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { ChangeUserComponent } from "app/pages/app-area/change-user/change-user.component";

var misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  collapse?: string;
  icontype: string;
  // icon: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/admin",
    title: "Administration Centre",
    type: "",
    icontype: "fas fa-user-shield",
  },
  {
    path: "/group-admin",
    title: "Group Settings",
    type: "",
    icontype: "fas fa-sitemap",
  },
  {
    path: "/company-admin",
    title: "Company Settings",
    type: "",
    icontype: "fas fa-briefcase",
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "fab fa-trello",
  },
  {
    path: "/change-user",
    title: "Change User",
    type: "function",
    icontype: "fas fa-user-edit",
  },
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  styleUrls: ["./sidebar.component.css"],
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: RouteInfo[] = [];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }

  private subscriptionUser: Subscription;

  isThereChat: boolean = false;

  user = {
    id: "",
    token: "",
    fullName: "",
    email: "",
    avatar: "",
    publicid: "",
    company: "",
    id_company: "",
    group: "",
    id_group: "",
    emailVerification: null,
  };

  Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  constructor(
    private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.subscriptionUser = this._authService.authUserChange.subscribe(
      async (value) => {
        this.menuItems = ROUTES.filter((menuItem) => menuItem);

        let user: any[] = value ? JSON.parse(value) : {};
        this.user.id = user["_id"];
        this.user.token = user["token"];
        this.user.fullName = user["name"] + " " + user["surname"];
        this.user.email = user["email"];
        this.user.emailVerification = user["emailVerification"];
        this.user.publicid = user["publicid"];
        this.user.company = user["company"] ? user["company"].name : "";
        this.user.id_company = user["company"] ? user["company"]._id : "";
        this.user.group = user["group"] ? user["group"].name : "";
        this.user.id_group = user["group"] ? user["group"]._id : "";

        const userRole = user["role"];

        const objIndexAdmin = this.menuItems.findIndex(
          (obj) => obj.path == "/admin"
        );
        if (userRole == "Admin") this.menuItems[objIndexAdmin].type = "link";
        else this.menuItems[objIndexAdmin].type = "";

        const objIndexCompanyAdmin = this.menuItems.findIndex(
          (obj) => obj.path == "/company-admin"
        );

        if (userRole != "User" && this.user.company)
          this.menuItems[objIndexCompanyAdmin].type = "link";
        else this.menuItems[objIndexCompanyAdmin].type = "";
        this.user.avatar = this._configService.url + user["avatar"];

        const objIndexGroupAdmin = this.menuItems.findIndex(
          (obj) => obj.path == "/group-admin"
        );

        if (userRole != "User" && this.user.group)
          this.menuItems[objIndexGroupAdmin].type = "link";
        else this.menuItems[objIndexGroupAdmin].type = "";
      }
    );
  }

  transform(value: string) {
    let now = moment(value).fromNow();
    return now;
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");
        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  menuitemFunction(path: string) {
    if (path == "/change-user") {
      //open mondal
      const modalRef = this.modalService.open(ChangeUserComponent, {
        size: "xl",
        centered: true,
        backdrop: "static",
      });
      modalRef.componentInstance.user = this.user.id;
      modalRef.componentInstance.token = this.user.token;
      modalRef.componentInstance.id_company = this.user.id_company;
    }
  }

  ngOnDestroy() {
    this.subscriptionUser.unsubscribe();
  }
}
