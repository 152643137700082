import { AvatarModule } from "ngx-avatar";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./navbar.component";
import {
  NgbDropdownModule,
  NgbToastModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AvatarModule,
    NgbDropdownModule,
    InfiniteScrollModule,
    NgbTooltipModule,
    NgbToastModule,
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
})
export class NavbarModule {}
