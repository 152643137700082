import { Injectable, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable()
export class StoreProvider {

  constructor() { }

  async setObject(key, value) {
    await Storage.set({
      key: key,
      value: JSON.stringify(value)
    });
  }

  async getObject(key) {
    const ret = await Storage.get({ key: key });
    const value = JSON.parse(ret.value);
    return value;
  }

  async setItem(key, value) {
    await Storage.set({
      key: key,
      value: value
    });
  }

  async getItem(key) {
    const { value } = await Storage.get({ key: key });
    return value;
  }

  async remove(key) {
    await Storage.remove({ key: key });
  }

  async keys() {
    const { keys } = await Storage.keys();
  }

  async clear() {
    await Storage.clear();
  }
}
